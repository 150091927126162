<template>
  <AgeGate />
  <div class="bg-blueish pt-[16vw] md:pt-[5vw] font-work overflow-hidden">
    <HankHero />
    <HankPresentation />
    <SectionRunningText text-color="white"
                        :text-items="['DOWNLOAD NOW']"
                        class="bg-[#418147] py-[3vw] md:py-[1vw] text-3xl !font-salongbeach"
    />
    <SectionGameDownload :stores="stores" />
  </div>
</template>


<script setup lang="ts">
import { onMounted, useState, useNuxtApp } from '#imports';
import useCookieHelpers from '~/composables/useCookieHelpers';

const { $lenisMount } = useNuxtApp();

const { storageAvailable, getCookie } = useCookieHelpers();
const hideSignUp = useState('display-form', () => false);

const stores = {
  amazon: 'https://o7n.co/mkt-mthigl-mthi-ttfweb-amazon',
  arcade: '',
  apple: 'https://o7.onelink.me/hhUd/2efz4odw',
  google: 'https://o7.onelink.me/hhUd/80let6hy',
  huawei: 'https://o7n.co/mkt-mthigl-mthi-ttfweb-huawei'
};

onMounted(() => {
  $lenisMount();
  setFormDisplay();
});

function setFormDisplay(){
  if (storageAvailable()) {
    if (localStorage.getItem('hank-age-gate')) {
      hideSignUp.value = true;
    }
  } 
  else {
    if (getCookie('hank-age-gate')) {
      hideSignUp.value = true;
    }
  }
}

</script>