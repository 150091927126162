<template>
  <section class="relative">
    <HankSvgBanana ref="banana"
                   class="absolute -top-[15vw] md:top-[3.3vw] max-md:right-[4vw] md:left-[3vw] max-w-[22.5vw] md:max-w-[10vw]"
    />
    <h2 class="pt-[15.75vw] md:pt-[6.5vw] mb-[10vw] md:mb-[2.25vw] text-[12.5vw] md:text-[3.75vw] text-center text-[#FF8BD4] font-salongbeach leading-none max-md:rotate-[-4.12deg]">
      <span class="max-md:text-[10vw]">SET SAIL FOR</span>
      <br>
      <span>TALKING HANK'S ISLAND</span>
    </h2>
    <p class="mx-auto mb-[10vw] md:mb-[3.6vw] max-w-[80vw] md:max-w-[46vw] text-white text-center text-[5.25vw] md:text-[1.3vw]">
      Start your adventure and relax in the charming treehouse with Hank. Help him take the bubbliest baths and fill his tummy with freshly made ice cream. Venture out to explore the lush island and meet adorable animal friends. Help the turtle recycle or give the lion a roaringly fabulous hairdo – find them all!
    </p>
    <a class="download-now-btn block mx-auto mb-[10vw] md:mb-[3.6vw] max-w-[86vw] md:max-w-[28vw]"
       href="https://o7n.co/mkt-mthigl-mthi-ttfweb-general"
       @click="trackEvent('click', { props: { campaign: campaign.name, position: `landing | download-now` } })"
    >
      <NuxtImg format="auto"
               class="w-full"
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/download-now-btn.png"
               alt="DOWNLOAD NOW"
               sizes="xs:86vw sm:86vw md:28vw lg:28vw xl:28vw xxl:28vw"
      />
    </a>
    <HankSvgSmile ref="smile"
                  class="absolute max-md:hidden top-[29vw] right-[11vw] max-w-[9vw]"
    />
    <Video video-playlist-id="E5ojSZar" />
    <HankSvgDivider class="mx-auto w-[150vw] md:w-[96.5vw] my-[14.25vw] md:my-[6.7vw]" />
    <h2 class=" mb-[13vw] md:mb-[5vw] text-[12.5vw] md:text-[3.75vw] text-center text-[#FF8BD4] font-salongbeach leading-none max-md:rotate-[-4.12deg]">
      <span class="max-md:text-[10vw]">Talking Hank's Adventure Guide:</span>
      <br>
      <span>Island Living</span>
    </h2>
    <SectionVideosFeature video-playlist-id="9qEgUADJ"
                          subtitle="You can also find us here:"
                          :badges="{
                            youtube: true,
                            netflix: false
                          }"
    />
    <HankSvgDivider class="mx-auto w-[150vw] md:w-[96.5vw] my-[14.25vw] md:my-[6.7vw]" />
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, useNuxtApp } from '#imports';
import { animate, scroll } from 'motion';
import { campaign } from '~/data/constants';

const { $event: trackEvent } = useNuxtApp();

const banana = ref(null);
const smile = ref(null);

onMounted(() => {
  scroll(({ y }) => {
    if (banana.value) {
      animate(banana.value.$el,
        { transform: `rotate(${-y.current / 4}deg)` },
        { duration:0 }
      );
    }

    if (smile.value) {
      animate(smile.value.$el,
        { transform: `rotate(${y.current}deg)` },
        { duration:0 }
      );
    }
  });
});
</script>

<style scoped lang="scss">
.download-now-btn {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0%, 100% {
    scale: 1;
    animation-timing-function: ease-in-out;
  }
  50% {
    scale: 1.05;
    animation-timing-function: ease-in-out;
  }
}
</style>
